import React from 'react';
import '../../App.css';

import './Contacto.css';

import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';





function AboutUs() {
  return (
    <>
      <div className='AUBase' >
        <Paper className='AboutUsPaper' elevation={6}>
            <section className='AboutUsPaperTit'>
                <h1 className='AUTitulo' >Contacto</h1>
            </section>
            <div className='SerMain'>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid  xs={12} sm={6}>
                  <div className='Ser2Main'>
                    <h1>Dirección</h1>
                    <h2>Urbanizacion Jorori,</h2>
                    <h2>Calle #3, Casa #7</h2>
                    <h2>Santa Cruz de la Sierra</h2>
                    <h2>Teléfono + 591 3 3424252</h2>
                    <h1>Correo electrónico</h1>
                    <h2>cavesrl@cavesrl.com.bo</h2>
                    <h1>Contactos</h1>
                    <h2>David Shriqui Méndez</h2>
                    <h2>Teléfono 72177029</h2>
                  </div>
                </Grid>
                <Grid  xs={12} sm={6}>
                <div className='mapa' >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d510.58606701307343!2d-63.16655754391411!3d-17.737261403932976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93f1e837785ff337%3A0x5a3627c710e1527c!2sCAVE%20SRL!5e0!3m2!1ses-419!2smx!4v1677655667824!5m2!1ses-419!2smx" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                </Grid>
              </Grid>
            </div>
            <section className='AboutUsPaperTit'>
                <h1 className='AUTitulo' >Contacto</h1>
            </section>

        </Paper>
        
      </div>

    </>
  );
}

export default AboutUs;
