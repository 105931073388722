import React from 'react';
import '../../App.css';

import './Clientes.css';

import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import comiagro from '../../assets/empresas/comiagro.png';
import compania from '../../assets/empresas/companiaindustrialdetabacossa.png';
import concretec from '../../assets/empresas/concretec.png';
import continental from '../../assets/empresas/continental.png';
import cre from '../../assets/empresas/cre.png';
import ese from '../../assets/empresas/ese.png';
import fabio from '../../assets/empresas/fabioandres.png';
import ferrotodo from '../../assets/empresas/ferrotodo.png';
import ferrovia from '../../assets/empresas/ferroviariaoriental.png';
import fps from '../../assets/empresas/fps.png';
import goldeneagle from '../../assets/empresas/goldeneagle.png';
import ingenio from '../../assets/empresas/ingeio.png';
import isa from '../../assets/empresas/isa.png';
import itika from '../../assets/empresas/itika.png';
import miswaco from '../../assets/empresas/miswaco.png';
import noni from '../../assets/empresas/noni.png';
import petrobras from '../../assets/empresas/petrobras.png';
import schlumberger from '../../assets/empresas/schlumberger.png';
import setar from '../../assets/empresas/setar.png';
import smith from '../../assets/empresas/smith.png';
import unagro from '../../assets/empresas/unagro.png';
import ventura from '../../assets/empresas/ventura.png';
import ypfb from '../../assets/empresas/ypbf.png';


function AboutUs() {
  return (
    <>
      <div className='AUBase' >
        <Paper className='AboutUsPaper' elevation={6}>
            <section className='AboutUsPaperTit'>
                <h1 className='AUTitulo' >Clientes</h1>
            </section>
            <div className='CliMain'>
              <h1>Estos son algunos de los más importantes clientes. Estas empresas han confiado en la calidad y seriedad de nuestros servicios.</h1>
            </div>
            <ImageList  cols={5} >
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}`}
                    srcSet={`${item.img}`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <section className='AboutUsPaperTit'>
                <h1 className='AUTitulo' >Clientes</h1>
            </section>

        </Paper>
        
      </div>

    </>
  );
}

export default AboutUs;

const itemData = [
  {
    img: comiagro,
    title: 'Comiagro',
  },
  {
    img: compania,
    title: 'Tabacos',
  },
  {
    img: concretec,
    title: 'Concretec',
  },
  {
    img: continental,
    title: 'Continental',
  },
  {
    img: cre,
    title: 'CRE',
  },
  {
    img: ese,
    title: 'ESE',
  },
  {
    img: fabio,
    title: 'Fabio',
  },
  {
    img: ferrotodo,
    title: 'Ferrotodo',
  },
  {
    img: ferrovia,
    title: 'Ferrovia',
  },
  {
    img: fps,
    title: 'FPS',
  },
  {
    img: goldeneagle,
    title: 'Golden Eagle',
  },
  {
    img: isa,
    title: 'ISA',
  },
  {
    img: itika,
    title: 'Itika',
  },
  {
    img: miswaco,
    title: 'Miswaco',
  },
  {
    img: noni,
    title: 'Noni',
  },
  {
    img: petrobras,
    title: 'Petrobras',
  },
  {
    img: schlumberger,
    title: 'Schlumberger',
  },
  {
    img: setar,
    title: 'Setar',
  },
  {
    img: smith,
    title: 'Smith',
  },
  {
    img: unagro,
    title: 'Unagro',
  },
  {
    img: ventura,
    title: 'Ventura',
  },
  {
    img: ypfb,
    title: 'YPFB',
  },
];