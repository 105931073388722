import React from 'react';
import './App.css';

import NavbarD from "./components/NavbarDark";
import Footer from "./components/Footer"

import Home from './components/pages/Home.js';
import Empresa from './components/pages/Empresa';
import Servicios from './components/pages/Servicios';
import Clientes from './components/pages/Clientes.js';
import Contacto from './components/pages/Contacto.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <NavbarD></NavbarD>
        <Routes>
          <Route exact path='/'element={<Home/>} />
          <Route exact path='/empresa' element={<Empresa/>} />
          <Route exact path='/clientes' element={<Clientes/>} />
          <Route exact path='/contacto' element={<Contacto/>} />
          <Route exact path='/servicios' element={<Servicios/>} />

        </Routes>
        <Footer></Footer>
      </Router>
    </>
  );
}

export default App;
