import React from 'react';
import '../App.css';
import './HeroSection.css';
import Grid from '@mui/material/Grid';

import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import ForestIcon from '@mui/icons-material/Forest';
import EngineeringIcon from '@mui/icons-material/Engineering';


function HeroSection() {
  return (
    <div className='hero-container'>
      <h1>Expertos en consultoría multidisciplinaria</h1>
      <p>Fiscalización de proyectos en calidad, salud, seguridad y medioambiente</p>
      <div className='MainContainer'>
        <div className='SubMainContainer'>
          <h1>Servicios Destacados</h1>
          <h2>Como empresa de servicios de consultoría multidisciplinaria de enfoque ambiental, salud ocupacional, seguridad industrial y social, podemos destacar los siguientes servicios:</h2>
        </div>
      </div>
      <div className='Servicios' >
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid  xs={12} sm={4}>
            <div className='Servicio' >
              <SafetyCheckIcon sx={{ fontSize: 80 }}></SafetyCheckIcon>
              <h1>Fiscalización</h1>
              <h1>de Proyectos</h1>
            </div>
          </Grid>
          <Grid  xs={12} sm={4}>
          <div className='Servicio' >
              <ForestIcon sx={{ fontSize: 80 }}></ForestIcon>
              <h1>Gestión</h1>
              <h1>Ambiental</h1>
            </div>
          </Grid>
          <Grid  xs={12} sm={4}>
          <div className='Servicio' >
              <EngineeringIcon sx={{ fontSize: 80 }}></EngineeringIcon>
              <h1>Asesoramiento en Sistemas de Gestión</h1>
            </div>
          </Grid>
        </Grid>
      </div>
      
    </div>
  );
}

export default HeroSection;
