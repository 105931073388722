import React from 'react';
import '../../App.css';

import './Servicios.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';



function Artistas() {
  return (
    <>
      <div className='SerBase' >
        <Card className='InverCard' elevation={6}>
            <section className='AboutUsPaperTit'>
                <h1 className='SerTitulo' >Servicios</h1>
            </section>
            <div className='SerMain'>
              <h1>Como empresa de servicios de consultoría multidisciplinaria de enfoque ambiental, salud ocupacional, seguridad industrial y social, algunos de estos servicios se detallan a continuación:</h1>
              <Card className='SerCard'>
                <CardMedia
                  sx={{ height: 240 }}
                  image={require('../../assets/fisca.png')}
                  title="Mision"
                  
                />
                <CardContent>
                  <Typography gutterBottom variant="h3" component="div" textAlign={'center'} >
                  Fiscalización de proyectos
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  La necesidad de realizar un trabajo Integral nos ha permitido crear programas de fiscalización de proyectos enmarcados en la Reglamentación de la Ley 1333 y responder además a las normas ISO 9001, ISO 14001, OSHAS 18001 y SA 8000, convirtiéndose en el lema de nuestra empresa para atender las acciones fundamentales de todo trabajo coordinado en la industria; cuidando los detalles en cuanto a calidad, seguridad, medio ambiente y responsabilidad social.
                  </Typography>
                </CardContent>
              </Card>
              <Card className='SerCard'>
                <CardMedia
                  sx={{ height: 240 }}
                  image={require('../../assets/ambien.png')}
                  title="Mision"
                  
                />
                <CardContent>
                  <Typography gutterBottom variant="h3" component="div" textAlign={'center'} >
                  Gestión Ambiental
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Elaboración de Fichas Ambientales, Manifiestos Ambientales, Estudios de Evaluación de Impacto Ambiental, PPM PASA- CD -4, Auditorías Ambientales, etc. Hemos adoptado la premisa de buscar soluciones acordes a las recomendaciones de la normativa legal vigente y la disponibilidad de cada cliente.
                  </Typography>
                </CardContent>
              </Card>
              <Card className='SerCard'>
                <CardMedia
                  sx={{ height: 240 }}
                  image={require('../../assets/monito.png')}
                  title="Mision"
                  
                />
                <CardContent>
                  <Typography gutterBottom variant="h3" component="div" textAlign={'center'} >
                  Realización de monitoreos ambientales y de seguridad
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Poseemos procedimientos para ofrecer nuestros servicios de monitoreo ambiental que involucra definición de procesos, muestreo, análisis, interpretación de resultados, verificación de cumplimiento de requisitos legales.
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  A través de la evaluación de las variables del proceso productivo que pueden impactar sobre los recursos del medio ambiente, y aplicando parámetros legales y normas establecidas, planificamos y ejecutamos Planes de Medición y Monitoreo, abarcando las siguientes áreas:
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Aguas superficiales y subterráneas
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Aguas para consumo humano
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Efluentes líquidos
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Calidad de aire
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Emisiones gaseosas
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Suelos
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Ruidos y vibraciones
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Mediciones en ambiente laboral
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Evaluar el cumplimiento legal en materia de Higiene, Seguridad y Medio Ambiente.
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Diseñar y proponer mecanismos de mitigación o de reducción de impactos
                  </Typography>
                  
                </CardContent>
              </Card>
            </div>            
            <section className='AboutUsPaperTit'>
                <h1 className='AUTitulo' >Servicios</h1>
            </section>

        </Card>
        
      </div>

    </>
  );
}

export default Artistas;
