import React from 'react';
import '../../App.css';

import './Empresa.css';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


function Inversionistas() {
  return (
    <>
      <div className='NEBase' >
        <Card className='InverCard' elevation={6}>
            <section className='AboutUsPaperTit'>
                <h1 className='NETitulo' >Nuestra Empresa</h1>
            </section>
            <div className='CardContainerInver'>       
              <div className='NEMain'>
                <h1>¿Quienes Somos?</h1>
                <h2>Consultora América Verde SRL,  desde su creación, ha desarrollado y perfeccionado una serie de servicios que han venido incorporándose en las diferentes industrias, instituciones y comunidades comprometidas con el desarrollo sostenible asistiéndolos en fiscalización de proyectos, gestión ambiental y asesoramiento en sistemas de gestión y el cumplimiento a las normas y leyes medio ambientales y de seguridad en el marco Nacional e Internacional.</h2>
              </div>
            </div>
              <Card className='NECard'>
                  <CardMedia
                      sx={{ height: 140 }}
                      image={require('../../assets/mision.png')}
                      title="Mision"
                      
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h2" component="div" textAlign={'center'} >
                        Misión
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                      Acompañar y asistir a nuestros clientes en el desarrollo de sus estrategias, políticas, procesos y cumplimiento efectivo que enmarcan las leyes ambientales y de seguridad del país, con un equipo multidisciplinario de profesionales con una sólida formación académica y amplia experiencia en el área.
                      </Typography>
                    </CardContent>
                  </Card>
              <Card className='NECard'>
              <CardMedia
                  sx={{ height: 140 }}
                  image={require('../../assets/vision.png')}
                  title="Vision"
                />
                <CardContent>
                  <Typography gutterBottom variant="h2" component="div" textAlign={'center'}>
                    Visión
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                  Nuestra visión como CAVE SRL, es lograr que todas las actividades del ser humano, puedan desarrollarse con seguridad y progresar en armonía con su medio ambiente, logrando un desarrollo sostenible capaz de garantizar un futuro mejor para las próximas generaciones.
                  </Typography>
                </CardContent>
              </Card>
            
            <section className='AboutUsPaperTit'>
                <h1 className='NETitulo' >Nuestra Empresa</h1>
            </section>

        </Card>
        
      </div>

    </>
  );
}

export default Inversionistas;
