import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div className='footer-container'>
      <section class='social-media'>
        <div class='social-media-wrap'>
              <Link to='/' className='social-logo'>
                <img className='logoFoot' src={require('./../assets/Cave-SRL-logo.png')} />
              </Link>
          <small class='website-rights'>Cave SRL © 2023</small>
          <div class='social-icons'>
            <a
              class='social-icon-link instagram'
              href='https://www.facebook.com/profile.php?id=100057288376805'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-facebook' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/cavesrl/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link instagram'
              href='http://www.cavesrl.com.bo/webmail'
              target='_blank'
              aria-label='Instagram'
            >
              <i class="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </section>
      <div className='fdesign'><h2>Web design & Development: pedrolalvarezf & Shraxler</h2></div>
    </div>
  );
}

export default Footer;
