import React from 'react';
import '../../App.css';
import Hero from '../HeroSection';


import './Home.css';



function Home() {
  return (
    <>
      <Hero></Hero>
      <div></div>
    </>
  );
}

export default Home;

          